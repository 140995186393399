<template>
    <modal
        class="block-user-modal"
        :adaptive="true"
        name="block-user-modal"
        height="auto"
        width="542"
        @before-open="beforeOpen"
        @before-close="beforeClose"
    >
        <div class="close-modal" @click="$modal.hide('block-user-modal')">
            <img src="@assets/img/icons/x.svg" alt="X" width="15px">
        </div>
        <div class="content">
            <h1>Block {{ userName }}</h1>
            <p>This user won’t be able to follow you and you will not see notifications and content from {{ userName }}</p>
            <div class="ml-auto actions">
                <button type="button" class="btn btn-white" @click="$modal.hide('block-user-modal')">
                    Cancel
                </button>
                <button type="button" class="ml-3 btn btn-primary" @click="toggleBlockUser">
                    Block
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: "BlockUserModal",
    data() {
        return {
            userId: null,
            userName: ""
        }
    },
    methods: {
        handleDescriptionBox(reportType) {
            if (reportType.requires_description) {
                this.shouldShowDescriptionBox = true;
            }
        },
        beforeOpen(event) {
            if (event.params?.userId && event.params?.userName) {
                this.userId = event.params.userId;
                this.userName = event.params.userName;
            } else {
                this.$notify({
                    group: "app-notifications",
                    type: "error",
                    text: "Can't report, try again later",
                    duration: 3000
                });
                event.cancel();
            }
        },
        beforeClose() {
            this.entityId = null;
            this.entityName = "";
        },
        toggleBlockUser() {
            this.$emit("toggle-block-user");
            this.$modal.hide("block-user-modal");
        }
    }
}
</script>
<style lang="scss" scoped>
.block-user-modal {
    z-index: 99999;
    .close-modal {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        z-index: 3;
        @media(max-width: $sm) {
            right: 15px;
            top: 15px;
            img {
                width: 15px;
            }
        }
    }
    /deep/ .vm--modal{
        background-color: #1C1C1E;
        border-radius: 10px;
        box-shadow: none;
        .content {
            padding: 35px 45px;
            display: flex;
            flex-direction: column;

            h1 {
                font-weight: 600;
                font-size: 20px;
                line-height: 20px;
                margin-bottom: 35px;
            }

            p {
                font-size: 18px;
                color: rgba(235, 235, 245, 0.6);
                margin-bottom: 30px;
            }
        }
    }
}
</style>
